import React from 'react';
import AdvanceSearchPage from '../views/pages/search/AdvanceSearchPage';



const Dashboard = React.lazy(() => import('../views/pages/dashboard/Dashboard'));
const Workarea = React.lazy(() => import('../views/pages/workarea/Workarea'));
const Search = React.lazy(() => import('../views/pages/search/Search'));
const AdvanceSearch = React.lazy(() => import('../views/pages/search/AdvanceSearch'));
const AdvanceSearchDetails = React.lazy(() => import('../views/pages/search/AdvanceSearchDetails'));
const DocumentType = React.lazy(() => import('../views/pages/documentType/components/DocumentType'));
const Documents = React.lazy(() => import('../views/pages/documents/Documents'));
const Department = React.lazy(() => import('../views/pages/setup/Department'));
const Roles = React.lazy(() => import('../views/pages/setup/Roles'));
const UserCreation = React.lazy(() => import('../views/pages/user/UserCreation'));
const Workflow = React.lazy(() => import('../views/pages/setup/Workflow'));
const States = React.lazy(() => import('../views/pages/setup/States'));
const Transition = React.lazy(() => import('../views/pages/setup/Transition'));
const WorkflowDocumentType = React.lazy(() => import('../views/pages/setup/WorkflowDocumentType'));
const ActivityLogs = React.lazy(() => import('../views/pages/reports/components/ActivityLogs'));
const Pages = React.lazy(() => import('../views/pages/documents/Pages'));
const Tags = React.lazy(() => import('../views/pages/setup/Tags'));
const Profile = React.lazy(() => import('../views/pages/user/Profile'));
const DocumentDetails = React.lazy(() => import('../views/pages/documents/DocumentDetails'));
const ACLs = React.lazy(() => import('../views/pages/setup/Acls'));
const Subscription = React.lazy(() => import('../views/pages/setup/Subscription'));
const Trash = React.lazy(() => import('../views/pages/trash/Trash'));
const FileExplore = React.lazy(() => import('../views/pages/setup/FileExplore'));
const BulkImport = React.lazy(() => import('../views/pages/utility_Tab/BulkImport'));
const Export = React.lazy(() => import('../views/pages/utility_Tab/Export'));
const RebuildSearchIndex = React.lazy(() => import('../views/pages/utility_Tab/BulkImport'));
const QueryFilter = React.lazy(() => import('../views/pages/utility_Tab/QueryFilter'));
const Utility = React.lazy(() => import('../views/pages/utility_Tab/Utility'));
const MetaDataTypes = React.lazy(() => import('../views/pages/metaDataTypes/MetaDataTypes'));
const DocumentTypeMetaData = React.lazy(() => import('../views/pages/setup/DocumentTypeMetaData'));
const Checkouts = React.lazy(() => import('../views/pages/checkouts/Checkouts'));
const UserAcls = React.lazy(() => import('../views/pages/user/UserAcls'));
const Permissions = React.lazy(() => import('../views/pages/setup/permissions'));
const DocumentsWorkflow = React.lazy(() => import('../views/pages/setup/documentsWorkflow'));
const AssignUser = React.lazy(() => import('../views/pages/documents/AssignUser'));
const AssignedDocuments = React.lazy(() => import('../views/pages/documents/AssignedDocuments'));
const SearchTemplate = React.lazy(() => import('../views/pages/search/SearchTemplate'));
const Notification = React.lazy(() => import('../views/pages/notification/components/Notification'));
const Events = React.lazy(() => import('../views/pages/setup/Events'));
const DocumentSubscription = React.lazy(() => import('../views/pages/documents/DocumentSubscription'));
const RecordDocuments = React.lazy(() => import('../views/pages/recordManagement/RecordDocuments'));
const DocumentLogs = React.lazy(() => import('../views/pages/recordManagement/DocumentLogs'));
const CompressionLog = React.lazy(() => import('../views/pages/reports/components/CompressionLog'));
const WatchFolderLog = React.lazy(() => import('../views/pages/reports/components/WatchFolderLog'));
const DocumentsLog = React.lazy(() => import('../views/pages/reports/components/DocumentsLog'));
const StateDocuments = React.lazy(() => import('../views/pages/workflow/components/StateDocuments'));
const Third_Party = React.lazy(() => import('../views/base/third-party/Third_Party'))
const DuplicateLog = React.lazy(() => import('../views/pages/reports/components/DuplicateLog'));
const DocumentEventLog = React.lazy(() => import('../views/pages/reports/components/DocumentEventLog'));
const CorruptFileLog = React.lazy(() => import('../views/pages/reports/components/CorruptFileLog'));
const Bookmarks = React.lazy(() => import('../views/pages/setup/Bookmarks'));
const StateDocumentDetails = React.lazy(() => import('../views/pages/workflow/components/StateDocumentDetails'));
const FileExplorer_Groups = React.lazy(() => import('../views/pages/fileexplorer/FileExplorer_Groups'));
const FileExplorer_Roles = React.lazy(() => import('../views/pages/fileexplorer/FileExplorer_Roles'));
const FileExplorer_DocumentTypes = React.lazy(() => import('../views/pages/fileexplorer/FileExplorer_DocumentTypes'));
const FileExplorerDocuments = React.lazy(() => import('../views/pages/fileexplorer/FileExplorerDocuments'));
const MailNotifications = React.lazy(() => import('../views/pages/setup/MailNotifications'));
const FileManager = React.lazy(() => import('../views/pages/file_explorer/FileManager'));
const FileManagerSearch = React.lazy(() => import('../views/pages/file_explorer/FileManagerSearch'));
// const DocumentPrevirw = React.lazy(() => import('../views/pages/documents/Docpreview'));



const routes = [
  { path: '/third-party/:token', exact: true, name: 'Third_Party', id: 'Third_Party', component: Third_Party },
  { path: '/workarea', exact: true, name: 'Work Area', id: 'workArea', component: Workarea },
  { path: '/dashboard', exact: true, name: 'Dashboard', id: 'dashboard', component: Dashboard },
  { path: '/search', exact: true, name: 'Search', id: 'search', component: Search },
  { path: '/advance/search', exact: true, name: 'Advance Search', component: AdvanceSearch },
  { path: '/advance/searchpage', exact: true, name: 'Advance Search Page', component: AdvanceSearchPage },
  { path: '/advancesearchdetails/:page', exact: true, name: 'Advance Search', component: AdvanceSearchDetails },
  { path: '/setup/document_type', exact: true, name: 'Document Type', id: 'documentType', component: DocumentType },
  { path: '/setup/mail_notifications', exact: true, name: 'Mail Notifications', id: 'mailNotifications', component: MailNotifications },
  { path: '/documents', exact: true, name: 'Documents', id: 'documents', component: Documents },
  { path: '/statedocuments', exact: true, name: 'State Documents', id: 'stateDocuments', component: StateDocuments },
  { path: '/acls/groups', exact: true, name: 'Groups', id: 'groups', component: Department },
  { path: '/acls/roles', exact: true, name: 'Roles', id: 'roleViewHeader', component: Roles },
  { path: '/statedocumentdetails/:id/:state_id', exact: true, name: 'State Document Details', id: 'statedocumentdetails', component: StateDocumentDetails },
  { path: '/setup/userCreation', exact: true, name: 'UserCreation', id: 'availableUserHeader', component: UserCreation },
  { path: '/setup/workflow', exact: true, name: 'Workflow', id: 'workflow', component: Workflow },
  { path: '/setup/states/:id', exact: true, name: 'States', component: States },
  { path: '/setup/documentsWorkflow/:id', exact: true, name: 'DocumentsWorkflow', component: DocumentsWorkflow },
  { path: '/setup/transition/:id', exact: true, name: 'Transition', component: Transition },
  { path: '/setup/workflowDocumentType/:id', exact: true, name: 'WorkflowDocumentType', id: 'workFlowDocumentType', component: WorkflowDocumentType },
  { path: '/setup/metadatatypes', exact: true, name: 'Metadata Types', id: 'metadataTypes', component: MetaDataTypes },
  { path: '/documents/Pages/', exact: true, name: 'Pages', component: Pages },
  { path: '/setup/tags', exact: true, name: 'Tags', id: 'tags', component: Tags },
  { path: '/profile/', exact: true, name: 'Profile', component: Profile, id: 'profile' },
  { path: '/document/:id', exact: true, name: 'DocumentDetails', component: DocumentDetails, id: 'documentDetails' },
  { path: '/acls/:id/:name/:app_label/:model/:role_id', exact: true, name: 'Acls', component: ACLs, id: 'acls' },
  { path: '/subscription/:id/:app_label/:model', exact: true, name: 'Subscription', id: 'subscription', component: Subscription },
  { path: '/trash', exact: true, name: 'Trash', id: 'trash', component: Trash },
  { path: '/fileExplore', exact: true, name: 'FileExplore', component: FileExplore, id: 'fileExplore' },
  { path: '/bulkImport', exact: true, name: 'BulkImport', component: BulkImport, id: 'bulkImport' },
  { path: '/export', exact: true, name: 'Export', component: Export },
  { path: '/rebuildSearchIndex', exact: true, name: 'RebuildSearchIndex', component: RebuildSearchIndex },
  { path: '/queryfilter', exact: true, name: 'QueryFilter', component: QueryFilter, id: 'queryFilter' },
  { path: '/utility/', exact: true, name: 'Utility', component: Utility, id: 'utility' },
  { path: '/document_type/:documentPk/metadata_type', exact: true, name: 'Document Type Meta Data', component: DocumentTypeMetaData },
  { path: '/checkouts', exact: true, name: 'Checked out documents', id: 'checkouts', component: Checkouts },
  { path: '/role/:id/:app_level/:model', exact: true, name: 'UserAcls', component: UserAcls, id: 'userAcls' },
  { path: '/permissions/:id/:name/', exact: true, name: 'Permissions', component: Permissions, id: 'permissions' },
  { path: '/assignuser/', exact: true, name: 'AssignUser', id: 'availableUserHeader', component: AssignUser },
  { path: '/assigneddocuments/', exact: true, name: 'Assigned Documents', id: 'assigneddocuments', component: AssignedDocuments },
  { path: '/searchtemplate/', exact: true, name: 'SearchTemplate', component: SearchTemplate, id: 'searchTemplate' },
  { path: '/notification/', exact: true, name: 'Notification', component: Notification, id: 'notifications' },
  { path: '/events/:id/:app_label/:model', exact: true, name: 'Events', component: Events, id: 'events' },
  { path: '/documentsubscription/', exact: true, name: 'DocumentSubscription', component: DocumentSubscription },
  { path: '/recorddocuments', exact: true, name: 'RecordDocuments', id: 'recordmanagement', component: RecordDocuments },
  { path: '/documentlogs/:id', exact: true, name: 'DocumentLogs', component: DocumentLogs, id: 'documentlogs' },
  { path: '/reports/activity_logs/', exact: true, name: 'ActivityLogs', id: 'ActivityLogs', component: ActivityLogs },
  { path: '/reports/compress_log', exact: true, name: 'CompressionLog', component: CompressionLog, id: 'CompressionLog' },
  { path: '/reports/watch_log', exact: true, name: 'watchLog', component: WatchFolderLog, id: 'watchLog' },
  { path: '/reports/documents_log', exact: true, name: 'DocumentsLog', component: DocumentsLog, id: 'DocumentsLog' },
  { path: '/reports/duplicate_log', exact: true, name: 'duplicateLog', component: DuplicateLog, id: 'duplicateLog' },
  { path: '/reports/documentevent_log', exact: true, name: 'documenteventLog', component: DocumentEventLog, id: 'documenteventLog' },
  { path: '/reports/corruptfile_log', exact: true, name: 'corruptfileLog', component: CorruptFileLog, id: 'corruptfileLog' },
  { path: '/setup/bookmarks', exact: true, name: 'set_up_bookmarks', component: Bookmarks, id: 'set_up_bookmarks' },
  // { path: '/fileexplorer', exact: true, name: 'file_explorer', component: FileExplorer_Groups, id: 'file_explorer' },
  { path: '/fileexplorer', exact: true, name: 'file_explorer', component: FileManager, id: 'file_explorer' },
  { path: '/fileexplorer/search', exact: true, name: 'file_explorer_search', component: FileManagerSearch, id: 'file_explorer_search' },
  { path: '/fileexplorer/:group_id', exact: true, name: 'file_explorer_roles', component: FileExplorer_Roles, id: 'file_explorer_roles' },
  { path: '/fileexplorer/:group_id/documentTypes', exact: true, name: 'file_explorer', component: FileExplorer_DocumentTypes, id: 'file_explorer_documentTypes' },
  { path: '/fileexplorer_documents/:id', exact: true, name: 'fileexplorer_documents', component: FileExplorerDocuments, id: 'fileexplorer_documents' },

];

export default routes;
